export type Config = {
  REACT_APP_COOKIE_DOMAIN: string;
  REACT_APP_ENVIRONMENT: 'development' | 'staging' | 'production' | 'test';
  REACT_APP_EXTRANET_GRAPHQL_URI: string;
  REACT_APP_GOOGLE_MAPS_API_KEY: string;
  REACT_APP_GTM_CONTAINER_ID: string;
  REACT_APP_HPP_URI: string;
  REACT_APP_IDP_ENDPOINT?: string;
  REACT_APP_RECAPTCHA_SITE_KEY: string;
  REACT_APP_RELEASE: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_SPLIT_AUTHORIZATION_KEY: string;
  REACT_APP_USER_ATTRIBUTES_ENDPOINT: string;
  REACT_APP_USER_POOL_CLIENT_ID: string;
  REACT_APP_USER_POOL_ID: string;
  REACT_APP_USER_POOL_REGION: string;
};

export function isValidConfig(value: unknown): value is Config {
  if (!value || typeof value !== 'object') {
    return false;
  }

  // Only verify the values of REACT_APP_ENVIRONMENT in the browser environment,
  // allowing tooling to write a placeholder value for replacement.
  const hasEnvironment =
    typeof window !== 'undefined'
      ? 'REACT_APP_ENVIRONMENT' in value &&
        (value.REACT_APP_ENVIRONMENT === 'development' ||
          value.REACT_APP_ENVIRONMENT === 'staging' ||
          value.REACT_APP_ENVIRONMENT === 'production' ||
          value.REACT_APP_ENVIRONMENT === 'test')
      : true;
  if (!hasEnvironment) {
    console.error(
      'REACT_APP_ENVIRONMENT must be set to "development", "staging", "production" or "test".',
    );
    return false;
  }

  const keys: Array<keyof Config> = [
    'REACT_APP_COOKIE_DOMAIN',
    'REACT_APP_ENVIRONMENT',
    'REACT_APP_EXTRANET_GRAPHQL_URI',
    'REACT_APP_GOOGLE_MAPS_API_KEY',
    'REACT_APP_GTM_CONTAINER_ID',
    'REACT_APP_HPP_URI',
    'REACT_APP_SPLIT_AUTHORIZATION_KEY',
    'REACT_APP_USER_POOL_REGION',
    'REACT_APP_USER_POOL_ID',
    'REACT_APP_USER_POOL_CLIENT_ID',
    'REACT_APP_USER_ATTRIBUTES_ENDPOINT',
  ];
  const hasError = keys.some((key) => {
    const missingKey = !(key in value);
    if (missingKey) {
      console.error(`Expected config key "${key}" was not found`);
      return true;
    }
    const wrongType = typeof (value as Config)[key] !== 'string';
    if (wrongType) {
      console.error(
        `Expected config key "${key}" of type string, not type ${wrongType}`,
      );
    }
    return false;
  });

  return !hasError;
}

let memoryConfig: Config;

function getConfig() {
  if (!memoryConfig) {
    if (
      '__EXTRANET_CONFIG__' in window &&
      isValidConfig(window.__EXTRANET_CONFIG__)
    ) {
      const { REACT_APP_IDP_ENDPOINT, ...values } = window.__EXTRANET_CONFIG__;
      memoryConfig = Object.freeze({
        ...values,
        REACT_APP_IDP_ENDPOINT: REACT_APP_IDP_ENDPOINT
          ? REACT_APP_IDP_ENDPOINT
          : undefined,
      });
      delete window.__EXTRANET_CONFIG__;
    } else {
      throw new Error('Invalid value for __EXTRANET_CONFIG__');
    }
  }

  return memoryConfig;
}

export const config = new Proxy<Config>({} as Config, {
  get(_, key: keyof Config) {
    return getConfig()[key];
  },
});
