import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useQuery } from '@apollo/client';
import useAuth from 'src/hooks/useAuth';
import { UserSignOutDocument } from 'src/graphql/queries/UserSignOut';

export const SignOut: React.FC = () => {
  const { signOut } = useAuth();
  const { loading } = useQuery(UserSignOutDocument);

  const state = useAsync(async () => {
    await signOut();
  }, []);

  if (state.loading || loading) {
    return null;
  }

  return <Redirect to="/sign-in" />;
};
