/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../__generated__/graphqlTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
const usedFragments: string[] = [];
export type UserSignOutQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserSignOutQuery = {
  __typename?: 'Query';
  viewer: { __typename?: 'Viewer'; signOut: boolean } | null;
};

export const UserSignOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserSignOut' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signOut' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSignOutQuery, UserSignOutQueryVariables>;
