import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';
import { Redirect } from 'react-router';
import useAuth from 'src/hooks/useAuth';

const LazyApiHealthCheckPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "api-health-check-page" */ './components/ApiHealthCheckOverview'
    ),
);

export const ApiHealthCheck = (
  props: React.ComponentProps<typeof LazyApiHealthCheckPage>,
) => {
  const { user } = useAuth();

  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      {user?.isStaff ? <LazyApiHealthCheckPage /> : <Redirect to={'/'} />}
    </Suspense>
  );
};
