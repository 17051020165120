import Cookies, { CookieAttributes } from 'js-cookie';
import { config } from 'src/config';
import isDevelopment from 'src/lib/isDevelopment';

export const createCookie = (
  name: string,
  token: string,
  attributes?: CookieAttributes,
) => {
  Cookies.set(name, token, {
    ...attributes,
    domain: !isDevelopment ? config.REACT_APP_COOKIE_DOMAIN : undefined,
    secure: !isDevelopment,
  });
};
